import axios, { AxiosInstance } from 'axios';
import { criticalToast, errorToast, successToast } from '@js/composables/useToasts';
import useErrorGuard from '@js/composables/useErrorGuard';

const { protocol, host } = window.location;
const baseURL = `${protocol}//${host}`;

/**
 * App APIs. Redirects on 401.
 *
 * @type {AxiosInstance}
 */
export const app = axios.create({ baseURL });
/**
 * Intercept requests and display catch all errors.
 */
app.interceptors.response.use((response) => response, async (error) => {
  // Handle user cancellations and browser navigations whilst API call is in progress.
  // Don't show error toast
  if (['canceled', 'Request aborted'].includes(error?.message)) {
    return Promise.reject(error);
  }

  // TODO: We need to handle server provided error messages
  // if (error.response?.data?.errors?.validation || error.response?.data?.errors?.toast) {
  //   const {}
  //   criticalToast({
  //     title: 'Something went wrong', description: error?.response?.statusText,
  //   });
  // }

  const errorCode = error?.response?.status;

  const { invokeGuard } = useErrorGuard();

  switch (errorCode) {
    case 401: // Unauthorised - Reload the page to handover redirect to symfony
      window.location.reload();
      break;
    case 403: // Forbidden - Replace page with 403 screen
      await invokeGuard(403);
      break;
    case 404: // Not found - Replace with 404 page
      await invokeGuard(404);
      break;
    case 500: // Internal server error - Replace page with 500 screen
      await invokeGuard(500);
      break;
    default:
      if (error.response?.data?.errors?.form) {
        //  Do not handle here - this is handled by individual forms
        break;
      }
      if (!error.response?.data?.errors?.toasts) {
        criticalToast({
          title: 'Something went wrong', description: error?.response?.statusText,
        });
      } else {
        const errors = error.response.data.errors.toasts;
        errors.forEach((singleError) => {
          criticalToast({
            title: singleError?.title ?? 'Something went wrong', description: singleError?.message,
          });
        });
      }
  }

  return Promise.reject(error);
});

/**
 * Authentication APIs. Does not redirect on 401
 *
 * @type {AxiosInstance}
 */
export const auth = axios.create({ baseURL });

/**
 * Intercept requests and display catch all errors.
 */
auth.interceptors.response.use((response) => {
  if (response?.data?.toasts !== undefined) {
    const toasts = response?.data?.data?.toasts;
    toasts.forEach((toast) => {
      successToast({
        title: toast?.title ?? '', description: toast?.message,
      });
    });
  }
  return response;
}, (error) => {
  if (error?.response?.status !== 401 && error.response?.data?.errors) { // The response does not specifically provides error messages.
    if (error.response?.data?.errors?.form !== undefined) {
    //  Do not handle here - this is handled by individual forms
    } else if (error.response?.data?.errors?.toasts !== undefined) {
      const errors = error.response.data.errors.toasts;
      errors.forEach((singleError) => {
        errorToast({
          title: singleError?.title ?? 'Something went wrong', description: singleError?.message,
        });
      });
    } else {
      criticalToast({
        title: 'Something went wrong', description: error?.response?.statusText,
      });
    }
  }
  return Promise.reject(error);
});
