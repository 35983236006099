import { useCreateResource } from '@js/composables/useEndpoint';
import { app } from './config/api';
import {useCreateEndpoint} from "../composables/useEndpoint";
import {unref} from "vue";

export default {
  ...useCreateResource(app, '/api/organisations'),
  duplicateCheck: () => useCreateEndpoint(
      (config) => (name, exceptionId = null) => app.post(`/api/organisations/check-name/${unref(exceptionId)}`, { name: unref(name)}, config),
  ),
};
