import { useCreateResource, useCreateEndpoint } from '@js/composables/useEndpoint';
import { unref } from 'vue';
import { app } from './config/api';

export default {
  ...useCreateResource(app, '/api/projects'),
  duplicateCheck: () => useCreateEndpoint(
      (config) => (name, exceptionId = null) => app.post(`/api/projects/check-name/${unref(exceptionId)}`, { name: unref(name) }, config),
  ),
  import: () => useCreateEndpoint(
    (config) => (formData) => app.post('/api/projects/import', formData, config),
  ),

  // sync projects
  syncProjects: () => useCreateEndpoint(
    (config) => (projectId) => app.get(`api/projects/${projectId}`, config),
  ),

  /**
   * Find the users permissions for all components
   */
  toggleActive: () => useCreateEndpoint(
    (config) => (id, value) => app.post(`/api/projects/${unref(id)}/active`, { active: unref(value) }, config),
  ),
};
