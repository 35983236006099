<template>
  <svg
    class="icon"
    :class="[size]"
  >
    <use :xlink:href="`/sprites/icons.svg#${name}`" />
  </svg>
</template>

<script setup>
import { defineProps, toRefs } from 'vue';

const props = defineProps({
  name: { type: String, required: true },
  size: { type: String, default: '' },
});
const {
  name, size,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.icon {
  width: 1em;
  height: 1em;
  display: inline;
  vertical-align: sub;

  &.xs {
    transform: scale(0.5);
  }

  &.sm {
    transform: scale(0.75);
  }

  &.md {
    transform: scale(1);
  }

  &.lg {
    transform: scale(1.5);
  }

  &.xl {
    transform: scale(2);
  }
}
</style>
