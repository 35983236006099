export default Object.freeze({
  SECTOR_SET: 'sectorSet',
  REGION_SET: 'regionSet',
  OFFICE_SET: 'officeSet',
  ORG_SET: 'orgSet',
  CONTRACTOR_SET: 'contractorSet',
  FRAMEWORK_SET: 'frameworkSet',
  PROJECT_SET: 'projectSet',
  USER_SET: 'userSet',
  GLOBAL_SEARCH: 'globalSearch',
});
