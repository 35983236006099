import { useCreateEndpoint } from '@js/composables/useEndpoint';
import { app } from './config/api';

export default {
  /**
   * Get the main menu information
   */
  getNavigation: () => useCreateEndpoint(
    (config) => () => app.get('/api/navigation', config),
  ),

  /**
   * Get the main menu information
   */
  getTranslations: () => useCreateEndpoint(
    (config) => () => app.get('/api/trans', config),
  ),

  /**
   * Global search dataset endpoint
   */
  globalSearch: () => useCreateEndpoint(
    (config) => (params) => app.get('/api/search', { params, ...config }),
  ),
};
