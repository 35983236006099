import { defineStore } from 'pinia';
import { useConfirmDialog, useScrollLock } from '@vueuse/core';
import { ref } from 'vue';

export default defineStore({
  id: 'modal',
  state: () => ({
    modals: {},
    lockBody: useScrollLock(ref(document.body)),
  }),
  getters: {
    getModal: (state) => (id) => state.modals[id],
    isAnyModalRevealed: (state) => {
      const instanceArray = Object.values(state.modals);
      return instanceArray.some(({ isRevealed }) => isRevealed);
    },
  },
  actions: {
    initialiseModal(id) {
      if (!(id in this.modals)) {
        this.modals[id] = useConfirmDialog();
      }
    },
    destroyModal(id) {
      if ((id in this.modals)) {
        this.modals[id].cancel();
        delete this.modals[id];
      }
    },
  },
});
