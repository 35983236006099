import { useCreateEndpoint, useCreateResource } from '@js/composables/useEndpoint';
import { unref } from 'vue';
import { app } from './config/api';

export default {
  ...useCreateResource(app, '/api/people'),
  duplicateCheck: () => useCreateEndpoint(
    (config) => (name, exceptionId = null) => app.post(`/api/people/check-name/${unref(exceptionId)}`, { name: unref(name) }, config),
  ),
  externalPartnerList: () => useCreateEndpoint(
    (config) => (params) => app.get('/api/people/external-partners', {
      params, ...config,
    }),
  ),
  /**
   * Find the persons permissions for all components
   */
  toggleActive: () => useCreateEndpoint(
      (config) => (ids, value) => app.post('/api/people/activated/set', { ids: [...unref(ids)], active: unref(value) }, config),
  ),
};
