import { useCreateResource, useCreateEndpoint } from '@js/composables/useEndpoint';
import { app } from './config/api';
import {unref} from "vue";

export default {
  ...useCreateResource(app, '/api/contractors'),
  getTrades: () => useCreateEndpoint(
    (config) => () => app.get('/api/trades', config),
  ),
  duplicateCheck: () => useCreateEndpoint(
      (config) => (name, exceptionId = null) => app.post(`/api/contractors/check-name/${unref(exceptionId)}`, { name: unref(name)}, config),
  ),
};
