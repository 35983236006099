import { unref } from 'vue';
import { format, parseISO } from 'date-fns';

/**
 *
 * @param {Date|String} inputDate
 * @param {String} dateFormat
 * @returns {String | null} Returns the formatted date or null if the date couldn't be parsed
 */
const formatParsedDate = (inputDate, dateFormat) => {
  let date = unref(inputDate);

  if (typeof date === 'string') {
    date = parseISO(date);
  }

  try {
    return format(date, dateFormat);
  } catch (e) {
    return null;
  }
};

export const dateFormats = {
  mediumDateFormat: 'eee dd MMM yyyy',
  mediumDateTimeFormat: 'eee dd MMM yyyy — HH:mm',
  mediumDateTimeSmall: 'dd/MM/yyyy - H:mm',
  longDateFormat: 'd MMMM yyyy',
  shortDateFormat: 'dd/MM/yyyy',
  monthYearFormat: 'MMMM yyyy',
  monthOnlyFormat: 'MMMM',
  timeFormat: 'HH:mm',
};

export const formatDateLong = (date, dateFormat = dateFormats.longDateFormat) => formatParsedDate(date, dateFormat);
export const formatDateLongFull = (date, dateFormat = `${dateFormats.longDateFormat} — ${dateFormats.timeFormat}`) => formatParsedDate(date, dateFormat);
export const formatDateShort = (date, dateFormat = dateFormats.shortDateFormat) => formatParsedDate(date, dateFormat);
export const formatDateMedium = (date, dateFormat = dateFormats.mediumDateFormat) => formatParsedDate(date, dateFormat);
export const formatDateTimeMedium = (date, dateFormat = dateFormats.mediumDateTimeFormat) => formatParsedDate(date, dateFormat);
export const formatDateTimeSmall = (date, dateFormat = dateFormats.mediumDateTimeSmall) => formatParsedDate(date, dateFormat);
export const formatTime = (date, dateFormat = dateFormats.timeFormat) => formatParsedDate(date, dateFormat);
export const formatDateMonthYear = (date, dateFormat = dateFormats.monthYearFormat) => formatParsedDate(date, dateFormat);
export const formatDateMonth = (date, dateFormat = dateFormats.monthOnlyFormat) => formatParsedDate(date, dateFormat);
