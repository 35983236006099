import {
  ref,
} from 'vue';
import router from '@js/router/router';

const errorPages = {
  500: () => import(/* webpackChunkName: "error" */'@js/views/errors/Error500.vue'),
  400: () => import(/* webpackChunkName: "error" */'@js/views/errors/Error400.vue'),
  403: () => import(/* webpackChunkName: "error" */'@js/views/errors/Error403.vue'),
  404: () => import(/* webpackChunkName: "error" */'@js/views/errors/Error404.vue'),
};

const lockGuard = ref(false);

export default () => {
  /**
   * Create and redirect to an error route
   *
   * @param {number|string}code
   */
  const invokeGuard = async (code) => {
    if (!(code in errorPages)) {
      console.error('No error page found for the code:', code);
      return;
    }
    if (lockGuard.value) {
      return;
    }
    lockGuard.value = true;
    const path = window.location.pathname.replace(/\+/g, '%2B').replace(/:/g, '%3A');
    const name = `_error-${code}`;

    await router.addRoute({
      name,
      path,
      meta: {
        overrideNavGuard: true,
        scope: false,
        side: false,
        container: false,
      },
      component: errorPages[code],
    });
    await router.replace({ name });
    lockGuard.value = false;
  };

  return {
    invokeGuard,
  };
};
