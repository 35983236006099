import { defineStore } from 'pinia';

export default defineStore({
  id: 'app',
  state: () => ({
    showSidebar: false, // Could be nav or activity pane depending on context - both cannot be on the same page
    pageLoading: false,
    openSearch: () => {},
    loading: 0,
    contextId: null,
    uploadProgress: 0,
    routeMeta: {},
  }),
  getters: {
    isEditContext: (state) => state.contextId !== null,
    isLoading: (state) => state.loading > 0,
  },
  actions: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    incLoading() {
      this.loading += 1;
    },
    decLoading() {
      setTimeout(() => { this.loading -= 1; }, 150); // Here to stop loading bar flickers when stacking loaders
    },
    clearLoading() {
      this.loading = 0;
    },
  },
});
