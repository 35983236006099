import { useCreateEndpoint } from '@js/composables/useEndpoint';
import { auth } from './config/api';

export default {
  sendAuth: () => useCreateEndpoint(
    (config) => (step, data) => auth.post('/api/login', data, {
      params: {
        step,
      },
      ...config,
    }),
  ),
  sendPasswordReset: () => useCreateEndpoint(
    (config) => (step, data) => auth.post('/api/password-reset', data, {
      params: {
        step,
      },
      ...config,
    }),
  ),
};
