import { AxiosResponse, AxiosInstance } from 'axios';
import { unref, Ref, ref } from 'vue';
import { createEventHook, EventHookOn } from '@vueuse/core';

/**
 * @typedef {Object} EndpointConfig
 * @property {AbortSignal} signal The cancellation signal config
 * @property {function(ProgressEvent):any} onUploadProgress The onUploadProgress callback config
 */

/**
 * @callback FetchClosure
 * @param {EndpointConfig} root0 Full name of the user
 * @returns {function(...*=):Promise<AxiosResponse<{data:Array,errors:Array,metadata:Array}>>} A function that returns an axios response
 */

/**
 *
 * @param {FetchClosure} endpoint
 * @returns {{onProgress: EventHookOn<any>, abort: function():void, fetch: function(...*=): Promise<AxiosResponse<{data:Array,errors:Array,metadata:Array}>>, progress: Ref<number>}} createEndpoint
 */
export const useCreateEndpoint = (endpoint) => {
  const progressEvent = createEventHook();
  const progress = ref(0);
  const controller = new AbortController();

  /**
   *
   * @param {ProgressEvent} e
   */
  const onUploadProgress = (e) => {
    progressEvent.trigger(e);
    progress.value = Math.round((e.loaded * 100) / e.total);
  };

  /**
   *
   * @type {function(*=): Promise<AxiosResponse>}
   */
  const fetch = endpoint({ signal: controller.signal, onUploadProgress });

  const cancel = () => {
    controller.abort();
  };

  return {
    fetch,
    abort: cancel,
    progress,
    onProgress: progressEvent.on,
  };
};

/**
 * Create a resource. Similar to Laravel resource routes it will auto create index, find, create, update and delete routes
 *
 * @param {AxiosInstance} instance
 * @param {string} route
 */
export const useCreateResource = (instance, route) => ({

  /**
   * List all resources
   */
  index: () => useCreateEndpoint(
    (config) => (params) => instance.get(route, {
      params, ...config,
    }),
  ),

  /**
   * Get a single resource entry
   */
  find: () => useCreateEndpoint(
    (config) => (id) => instance.get(`${route}/${unref(id)}`, config),
  ),

  /**
   * Create a new resource
   */
  create: () => useCreateEndpoint(
    (config) => (data) => instance.post(route, data, config),
  ),

  /**
   * Update resource
   */
  update: () => useCreateEndpoint(
    (config) => (data, id) => instance.post(`${route}/${unref(id)}`, data, config),
  ),

  /**
   * Delete one or more resources
   */

  delete: () => useCreateEndpoint(
    (config) => (ids) => instance.post(
      `${route}/delete`,
      null,
      {
        params: {
          ids: [...unref(ids)],
        },
        ...config,
      },
    ),
  ),
});
