import { unref } from 'vue';
import { useCreateResource, useCreateEndpoint } from '@js/composables/useEndpoint';
import { app } from './config/api';

export default {
  ...useCreateResource(app, '/api/users'),
  /**
   * Get the user permission structure for all components
   */
  permissionStructure: () => useCreateEndpoint(
    (config) => () => app.get('/api/users/permissions', config),
  ),

  /**
   * Find the users permissions for all components
   */
  findPermissions: () => useCreateEndpoint(
    (config) => (id) => app.get(`/api/users/${unref(id)}/permissions`, config),
  ),

  /**
   * Update the users permissions for all components
   */
  updatePermissions: () => useCreateEndpoint(
    (config) => (data, id) => app.post(`/api/users/${unref(id)}/permissions`, data, config),
  ),

  /**
   * Find the users permissions for all components
   */
  toggleActive: () => useCreateEndpoint(
    (config) => (ids, value) => app.post('/api/users/activated/set', { ids: [...unref(ids)], active: unref(value) }, config),
  ),

  /**
   * Find the users permissions for all components
   */
  duplicateCheck: () => useCreateEndpoint(
      (config) => (email, exceptionId = null) => app.post(`/api/users/check-email/${unref(exceptionId)}`, { email: unref(email) }, config),
  ),

};
