import { useCreateResource, useCreateEndpoint } from '@js/composables/useEndpoint';
import { app } from './config/api';

export default {
  offices: {
    ...useCreateResource(app, '/api/offices'),
    import: () => useCreateEndpoint(
      (config) => (formData) => app.post('/api/offices/import', formData, config),
    ),
  },
  sectors: { ...useCreateResource(app, '/api/sectors') },
  regions: { ...useCreateResource(app, '/api/regions') },
};
