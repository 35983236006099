import { defineStore } from 'pinia';
import { User } from '@js/api';

export default defineStore({
  id: 'permissions',
  state: () => ({
    potential: '',
    flags: [],
  }),
  getters: {
    // allPermissions: (state) => [...state.globalPermissions, ...state.contextPermissions],
  },
  actions: {
    async getPermissions() {
      await User.getUserPermissions().fetch().then(({ data: { data: { permissions: { flags, potential } } } }) => {
        this.$patch((state) => {
          state.potential = potential;
          state.flags = flags;
        });
      }).catch((error) => {
        console.error('Error retrieving current user profile data:', error);
      });
    },
  },
});
