import { useCreateEndpoint } from '@js/composables/useEndpoint';
import { app } from './config/api';

export default {
  customisation: {
    getBrand: () => useCreateEndpoint(
      (config) => () => app.get('/api/customisation', config),
    ),
    setBrand: () => useCreateEndpoint(
      (config) => (data) => app.post('/api/customisation', data, config),
    ),
  },
  translations: {
    getTranslations: () => useCreateEndpoint(
      (config) => () => app.get('/api/translations', config),
    ),
    setTranslations: () => useCreateEndpoint(
      (config) => (data) => app.post('/api/translations', data, config),
    ),
  },

};
