import { Ref, watch, unref } from 'vue';

import { UseConfirmDialogReturn } from '@vueuse/core';

import useModalStore from '@js/store/modal.store';
import { storeToRefs } from 'pinia';
/**
 *
 * @param {Ref<string>|string} modalId
 * @returns {{cancelAll: Function, modal: UseConfirmDialogReturn, destroy: Function}} The useModal instance
 */
export default (modalId) => {
  const modalStore = useModalStore();
  const { lockBody } = storeToRefs(modalStore);
  const {
    getModal, initialiseModal, destroyModal, isAnyModalRevealed,
  } = modalStore;

  const id = unref(modalId);

  initialiseModal(id);

  const modal = getModal(id);

  // TODO: This needs moving to a central point to avoid multiple watchers
  watch(() => modal.isRevealed, (open) => {
    lockBody.value = open;
  });

  /**
   * Cancel and destroy this modal instance
   */
  const destroy = () => {
    destroyModal(id);
  };

  return {
    modal,
    destroy,
    isAnyRevealed: isAnyModalRevealed,
  };
};
