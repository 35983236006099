const CONST = {
  ROLE_EMPLOYEE: 'ROLE_EMPLOYEE',
  ROLE_CONTRACTOR: 'ROLE_SUPPLY_CHAIN_PARTNER',
  ROLE_FRAMEWORK: 'ROLE_FRAMEWORK_USER',
  ROLE_ORGANISATION: 'ROLE_CUSTOMER_USER',
  VALIDATION_WD_EMAIL_SUBSTRING: 'willmottdixon.',
};

// TODO: Terminology for this - should it match the translations

CONST.USER_ROLES = [
  {
    KEY: CONST.ROLE_EMPLOYEE,
    VALUE: 'Employee',
  },
  {
    KEY: CONST.ROLE_CONTRACTOR,
    VALUE: 'Supply Chain Partner',
  },
  {
    KEY: CONST.ROLE_FRAMEWORK,
    VALUE: 'Framework User',
  },
  {
    KEY: CONST.ROLE_ORGANISATION,
    VALUE: 'Customer',
  },
];

export default CONST;

export const roleByKey = (KEY) => CONST.USER_ROLES.find(((item) => item.KEY === KEY)?.VALUE);
export const keyByRole = (ROLE) => CONST.USER_ROLES.find(((item) => item.VALUE === ROLE)?.KEY);
