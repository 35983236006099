import {
  createApp, defineAsyncComponent, ComponentPublicInstance,
} from 'vue';
import Icon from '@js/components/global/Icon.vue';
import 'mosha-vue-toastify/dist/style.css';
import '@scss/app.scss';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/alert';
import useModal from '@js/composables/useModal';
import { MODAL } from '@js/const';
import router from '@js/router/router';

const errors = [];

/**
 * app.js
 * Configuration for Vue JS. Add global components and imports here.
 * Called on every page. Options can be passed to RootComponent inside of page level JS file: https://v3.vuejs.org/guide/instance.html#the-root-component
 */

/**
 * @param {object} root0
 * @param {Function} root0.setup
 * @param {object} root0.components
 * @returns {ComponentPublicInstance<*>} Returns the Vue root instance
 */
export default ({ setup, components }) => {
  const app = createApp({
    performance: true,
    compilerOptions: {
      // Change the Vue delimiter in Twig to avoid collisions.
      delimiters: ['${', '}$'],
    },
    components: {
      DeploymentBar: defineAsyncComponent(() => import('@js/components/DeploymentBar.vue')),
      ...components,
    },
    setup() {
      return {
        ...setup(),
      };
    },
  })
  // Add plugins and extra features
  // These components will be included in all child components (similar to plugins)
    .component('Icon', Icon);

  /**
   * Global error handler
   *
   * @param {Error} err
   * @returns {Promise<void>}
   */
  app.config.errorHandler = async (err) => {
    errors.push(err);
    console.error(err);

    // report error to tracking services

    if (errors.length > 1) {
      return;
    }

    const { modal: globalDialog } = useModal(MODAL.GLOBAL_DIALOG);

    const backPath = router.options.history.state.back;

    const backButton = () => {
      if (backPath) {
        return {
          text: 'Go Back & Reload',
          styleClass: 'btn-secondary px-5',
        };
      }
      return null;
    };
    const goBack = () => {
      window.location.replace(backPath);
    };

    const { isCanceled } = await globalDialog.reveal({
      title: 'Critical Error',
      body: `
<p class="fs-5 mb-0">The application has encountered an unexpected error and needs to be reloaded. Contact support if this message persists.</p>`,
      icon: 'warning',
      persistent: true,
      headerColor: 'danger',
      stripes: true,
      buttons: {
        confirm: {
          text: 'Reload',
          styleClass: 'btn-danger px-5',
        },
        cancel: backButton(),
      },
    });

    if (isCanceled) {
      goBack();
    } else {
      window.location.reload();
    }
  };
  return app;
};
